<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        Upload Food Menu Files
                        <p>
                            <small>
                                Bulk Upload of Food Items
                            </small>
                        </p>
                    </template>

                    <template v-slot:toolbar>
                        <a href="/media/excel/foodmenus.xls" target="_blank" class="btn btn-primary">
                            Sample File
                            <i class="fa fa-download"></i>
                        </a>
                    </template>

                    <template v-slot:body>
                        <div>
                            <div class="attachments-operation">
                                <b-container fluid v-if="eventId">
                                    <form @submit.prevent="handleSubmitOperation" enctype="multipart/form-data">
                                        <b-row>
                                            <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                                                <b-row>
                                                    <b-col sm="5">
                                                        <b-form-group
                                                            label="File"
                                                            label-for="import_file"
                                                            :invalid-feedback="formErrors.first('import_file')"
                                                        >
                                                            <b-form-file
                                                                id="import_file"
                                                                accept="application/vnd.ms-excel"
                                                                v-model="formFields.import_file">
                                                            </b-form-file>
                                                            <b-form-invalid-feedback
                                                                :state="(!(formErrors.has('import_file')))">
                                                                {{formErrors.first('import_file')}}
                                                            </b-form-invalid-feedback>
                                                            <b-form-invalid-feedback
                                                                :state="(!(formErrors.has('0')))">
                                                                {{formErrors.first('0')}}
                                                            </b-form-invalid-feedback>
                                                            <b-form-invalid-feedback
                                                                :state="(!(formErrors.has('1')))">
                                                                {{formErrors.first('1')}}
                                                            </b-form-invalid-feedback>
                                                            <b-form-invalid-feedback
                                                                :state="(!(formErrors.has('2')))">
                                                                {{formErrors.first('2')}}
                                                            </b-form-invalid-feedback>
                                                            <b-form-invalid-feedback
                                                                :state="(!(formErrors.has('3')))">
                                                                {{formErrors.first('3')}}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col sm="12">
                                                        <b-button
                                                            size="sm"
                                                            type="submit"
                                                            variant="primary"
                                                            :disabled="global.pendingRequests > 0"
                                                        >
                                                            <i v-show="global.pendingRequests > 0"
                                                               class="fa fa-spin fa-spinner"></i>
                                                            <i class="fa fa-save fa-sm"></i>
                                                            Import File
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </form><!--/form-->
                                </b-container><!--/b-container-->
                            </div><!--/.attachments-operation-->
                        </div>
                    </template>
                </KTCard>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import Error from "@/core/services/Error";
    import {request} from "@/core/services/Request";
    import {itemUpdated} from "@/util/notify";
    import {getPropertyId} from "@/util/globalFunction";

    const DEFAULT_FORM_STATE = {
        import_file: null,
        _method: 'post',
    };

    export default {
        data() {
            return {
                formFields: {...DEFAULT_FORM_STATE},
                formErrors: new Error({}),
                eventId: getPropertyId() ? getPropertyId() : null,
            }
        },
        components: {
            KTCard
        },
        mounted() {
        },
        methods: {
            async handleSubmitOperation() {
                let formData = new FormData();
                formData.append('import_file', this.formFields.import_file);

                try {
                    const response = await request({
                        url: '/fnb/food/menus/import',
                        method: 'post',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then((response) => {
                        this.$global.importSuccess()
                        this.formFields = {...this.DEFAULT_FORM_STATE}
                    });
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }
                }
            }

        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
